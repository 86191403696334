.artiste-detail-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .artiste-detail-content {
    background-color: #7C3302 !important; /* Couleur de fond spécifiée */
    color: white; /* Couleur du texte pour un meilleur contraste */
    padding: 30px;
    border-radius: 8px;
    max-width: 1000px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    color: white; /* Couleur du bouton de fermeture */
    cursor: pointer;
  }
  
  .artiste-detail-content img {
    width: 300px; /* Limite la largeur maximale à 300px */
    height: auto; /* Maintient le ratio d'aspect */
    margin-bottom: 15px;
  }
  
  .artiste-detail-content h4 {
    margin-bottom: 20px;
    color: #C4CEDE;
  }
  
  .artiste-detail-content p {
    margin-bottom: 20px;
    max-width: 800px;
  }
  
  .artiste-detail-content a {
    color: #C4CEDE; /* Couleur dorée pour le lien */
    text-decoration: none;
    margin-top: 20px;
    display: inline-block;

  }

  a:hover {
    color: #BF8B63; /* Couleur au survol */
  }