@font-face {
  font-family: 'ApexBrush';
  src: url('../public/font/FontsFree-Net-apex-brush-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Adamina';
  src: url('../public/font/Adamina-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'InriaSerif';
  src: url('../public/font/InriaSerif-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Corbel';
  src: url('../public/font/Adamina-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.App {
  background-color: beige;
  color: #1F1E13;
  font-family: 'Corbel';
  line-height: 1.7em;
}

h1 {
  font-family: 'ApexBrush';
  font-size: 58px;
  text-align: center;
  line-height: 1.2em;
  padding: 1% 1%;
}

h2 {
  font-family: 'ApexBrush';
  font-size: 48px;
  text-align: center;
  line-height: 1em;
  padding: 1% 1%;
  margin-top: 1%;
}

h3 {
  font-family: 'Adamina';
  font-size: 42px;
  text-align: center;
  line-height: 1.7em;
  padding: 1% 1%;
}

h4 {
  font-family: 'Adamina';
  font-size: 20px;
  text-align: center;
  padding: 1% 1%;
}

.vidéo , iframe{
  display: flex;
  justify-content: center;
  align-items: center;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

li {
  color: #1F1E13;
  font-family: 'Corbel';
}

p {
  text-align: left;
  padding: 1% 1%;
  
}

.Salouva {
  max-width: 100%;
  height: auto;
}

/* Media Queries pour le responsive design */

@media (max-width: 1024px) {
  h1 { font-size: 48px; }
  h2 { font-size: 40px; }
  h3 { font-size: 36px; }
}

@media (max-width: 768px) {
  h1 { font-size: 40px; }
  h2 { font-size: 34px; }
  h3 { font-size: 30px; }
  h4 { font-size: 18px; }
  
  .App { padding: 0 0px; }
}

@media (max-width: 480px) {
  h1 { font-size: 32px; }
  h2 { font-size: 28px; }
  h3 { font-size: 24px; }
  h4 { font-size: 16px; }
  
  p, span { padding-left: 0; }
}

@media (max-width: 360px) {
  h1 { font-size: 28px; }
  h2 { font-size: 24px; }
  h3 { font-size: 20px; }
  h4 { font-size: 14px; }
  
  .App { line-height: 1.5em; }
}