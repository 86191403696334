.acceuil {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.acceuil > * {
    width: 95%;
    max-width: 100%;
    padding: 2%;
}

.video {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    width: 90%;
    padding-left: 4%;

}

.hero {
    display: flex;
    flex-direction: row;
    width: 95%;
    gap: 2%;
}

.herogauche {
    max-width: 100%;
    width: 45%;
    max-width: 479px;
    padding-top: 150px;
    
    
}

.herodroite {
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.bandeau {
    background-color: #7C3302;
    color: #E0DA4F;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom:5%;
    width: 99%;
}

.futur {
    padding-left: 5%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    width: 80%;
    padding-bottom: 10px;
}

.actualité{
    padding-left: 2%;
}

.soc{
 display: flex;
 justify-content: center;
 gap: 2%;
}

.sectionG {
    width: 100%;
    display: flex;
    align-items: center;
}
p{
    margin: 0%;
}

h4{
    font-family: 'Corbel';
    margin: 0%;
}

.invitation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: auto;
    padding: 10px;
}

.compt {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 60px;
    background-color: #7C3302;
    color: #E0DA4F;
    margin-bottom: 1%;
}

.mobil {
    background-color: #7C3302;
    width: 98%;
    color:#E0DA4F;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  
}

.logoC{
    height: auto;
    background-color: #A26563;
}

@media screen and (max-width: 768px) {
    .hero {
        flex-direction: column;
        align-items: center;
    }

    .herogauche, .herodroite {
        width: 95%;
    }

    .bandeau {
        flex-direction: column;
        gap: 20px;
    }

    .futur {
        flex-direction: column;
        gap: 30px;
        align-items: center;
    }

    .sectionG, .invitation {
        width: 100%;
    }

    .compt {
        flex-direction: column;
        gap: 30px;
    }

    .E {
        display: none;
    }
}

@media screen and (min-width: 769px) {
    .mobil {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .acceuil {
        gap: 20px;
    }

    .futur {
        gap: 20px;
    }

    .bandeau, .compt {
        gap: 15px;
    }

    .sectionG{
        flex-direction: column;
    }

    .invitation {
        width: 80%;
    }
}