.donde {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    line-height: 2em;
}

.Where {
    background-color: #7C3302;
    color: beige;
    width: 100%;
    
}

.lugar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
}

.lugar img {
    width: 500px;
    height: auto;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    padding-top: 1%;
    max-width: 100%;
}

.ou{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 1%;
}

p{
    text-align: left;
}

.space{
    padding-bottom: 2em;
}

/* Media Queries pour le responsive */
@media screen and (max-width: 1024px) {
    .lugar img {
        width: 100%;
        max-width: 500px;
    }
}

@media screen and (max-width: 768px) {
    .donde {
        padding-left: 0;
        padding-right: 0;
    }

    .lugar {
        flex-direction: column;
    }

    .lugar img {
        width: 90%;
        max-width: 450px;
    }
}

@media screen and (max-width: 480px) {
    .lugar img {
        width: 95%;
        max-width: none;
    }
}

/* Nouvelle media query pour 360px */
@media screen and (max-width: 360px) {
    .donde {
        padding-left: 1%;
        padding-right: 1%;
    }

    .lugar img {
        width: 80%; /* Utilise presque toute la largeur disponible */
    }

    .Where {
        font-size: 0.9em; /* Réduit légèrement la taille de la police */
    }

    .lugar {
        padding: 10px 0; /* Ajoute un peu d'espace vertical */
    }
}