.footer {
    background-color: beige;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    color: #7C3302;
    font-family: 'Adamina', serif;
    font-weight: bold;
    font-size: 15px;
    padding: 20px;
}

.footer-logo {
    display: flex;
    justify-content: flex-end;
    width: 30%;
}

.footer-logo img {
    height: 250px;
    width: auto;
    max-width: 100%;
}

.contenus {
    width: 100%;
    display: flex;
    flex-direction: column;
}


.columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    
}

.footer-column {
    text-align: start;
    flex: 1;
}

.footer-column h3{
    text-align: start;
}

.footer-signature {
    text-align: center;
    padding: 10px 0;
    filter :blur(0.7px)
}

a {
    text-decoration: none;
    color: inherit;
    transition: color 0.3s ease;
}

a:hover {
    color: #283527;
}

/* Responsive Styles for Tablets */
@media (max-width: 1024px) {
    .footer {
        font-size: 14px;
    }

    .footer-logo img {
        height: 200px;
    }
}

@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        align-items: center;
    }

    .contenus {
        flex-direction: column;
        align-items: center;
    }

    .footer-logo {
        justify-content: center;
        width: 100%;
        margin-bottom: 20px;
    }

    .footer-container {
        width: 100%;
    }

    .columns {
        flex-direction: column;
        align-items: center;
    }

    .footer-column {
        margin-bottom: 20px;
        width: 100%;
    }
}

/* Responsive Styles for Mobile */
@media (max-width: 480px) {
    .footer {
        padding: 10px;
        font-size: 12px;
    }

    .footer-logo img {
        height: 150px;
    }

    .footer-signature {
        padding: 5px 0;
    }

    #mobil, #mobile {
        display: none;
    }
}

/* Responsive Styles for Very Small Screens */
@media (max-width: 360px) {
    .footer {
        padding: 5px; /* Réduit le padding pour les très petits écrans */
        font-size: 10px; /* Réduit la taille de la police pour une meilleure lisibilité */
    }

    .footer-logo img {
        height: 120px; /* Réduit la hauteur du logo pour les très petits écrans */
    }

    .footer-signature {
        padding: 3px 0; /* Réduit le padding pour économiser de l'espace */
    }

    .columns {
        padding: 0; /* Supprime le padding pour les très petits écrans */
    }

    .footer-column {
        margin-bottom: 10px; /* Réduit l'espacement entre les colonnes */
    }
}