/* ContactForm.css */

.contact{
    padding-bottom: 10px;
    background-color: #7C3302; /* Couleur de fond de la page */
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;
  }
  
  form {
    background-color: #ffffff; /* Fond blanc pour le formulaire */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px
  }
  

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    color:#141710
  }
  
  textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .identite{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    
  }
 
  .prenom{
    width: 50%;
  }

  .nom{
    width: 50%;
  }

.centre{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

h2{
  padding: 0;
  margin: 0;
}