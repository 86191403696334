.carousel-artistes {
  max-width: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15em;
  padding: 0 10%;
  
}

.artistes {
  display: flex;
  gap: 2%;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 100%;
  transition: transform 0.3s ease;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  width: 3em;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow:first-of-type {
  left: 2%;
}

.arrow:last-of-type {
  right: 2%;
}

.artiste-container {
  flex: 0 0 calc(33.33% - 1.33%);
  max-width: calc(33.33% - 1.33%);
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  overflow: hidden;
  padding-bottom: 0.5em;
  text-decoration: none;
  color: inherit;
}

.artiste-container:hover {
  transform: scale(1.05);
}

.artiste-image {
  width: auto;
  height: auto;
  max-height: 11em;
  max-width: 100%;
  object-fit: contain;
  margin-bottom: 0.5em;
}

.artiste-name {
  text-align: center;
  font-size: 0.875em;
  margin: 0;
  padding: 0.3em 0;
  width: 100%;
  word-wrap: break-word;
}

/* Responsive Design */
@media (max-width: 75em) { /* 1200px */
  .carousel-artistes {
    padding: 0 8%;
  }

  .artistes {
    width: 84%;
  }

  .artiste-container {
    flex: 0 0 calc(50% - 1%);
    max-width: calc(50% - 1%);
  }

  .arrow {
    width: 2.5em;
    height: 2.5em;
  }
}

@media (max-width: 48em) { /* 768px */
  .carousel-artistes {
    padding: 0 6%;
    height: 13em;
  }

  .artistes {
    width: 88%;
  }

  .artiste-container {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .artiste-image {
    max-height: 13em;
  }

  .arrow {
    width: 2em;
    height: 2em;
  }
}

@media (max-width: 30em) { /* 480px */
  .carousel-artistes {
    height: 11em;
  }

  .artiste-container {
    max-width: 80%;
  }

  .artiste-image {
    max-height: 11em;
  }

  .artiste-name {
    font-size: 0.75em;
  }

  .arrow {
    width: 1.75em;
    height: 1.75em;
  }
}

@media (max-width: 22.5em) { /* 360px */
  .carousel-artistes {
    height: 9em;
  }

  .artiste-image {
    max-height: 8em;
  }

  .artiste-name {
    font-size: 0.6875em;
  }

  .arrow {
    width: 1.5em;
    height: 1.5em;
  }
}