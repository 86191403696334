.Appel {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 2%;
    padding-right: 2%;
    box-sizing: border-box;
}

.topapp {
    width: 100%;
    max-width: 80%;
    text-align: lef;
    padding: 2% 5%;
    box-sizing: border-box;
}

.bt {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: auto;
    justify-content: center;
    width: 100%;
    padding:  3% ;
}

.limite {
    background-color: #7C3302;
    color: #E0DA4F;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: auto;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

/* Media Queries pour le responsive */
@media screen and (max-width: 768px) {
    .topapp, p {
        max-width: 90%;
    }

    .bt {
        flex-direction: column;
    }

    .limite {
        width: 80%;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 480px) {
    .topapp, p {
        max-width: 100%;
    }

    .limite {
        width: 95%;
    }
}