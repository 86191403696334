.menu {
    padding: 0% 7%;
    margin: 0%;
    display: flex;
    align-items: center;
    color: #7C3302;
    background-color: beige;
    font-family: 'Corbel';
    font-weight: bold;
    position: relative;
    z-index: 1;
    max-width: 100%;
}

.menu-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 75%;
}

.menu::before {
    content: '';
    position: absolute;
    top: 25%;
    left: 0;
    width: 100%;
    height: 50px;
    background-image: url('../../assets/shiromani.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    overflow: hidden;
}

.Facclogo {
    height: 300px;
    position: relative;
    z-index: 2;
}

.menu a {
    text-decoration: none;
    color: inherit;
}

.menu a:hover {
    color: #283527;
}

.menu p, .menu span, .nav-dropdown > span {
    font-size: 25px;
    margin: 0;
    cursor: pointer;
    width: 150px;
    text-align: left;
}

.dropdown {
    display: none;
    position: absolute;
    width: auto;
    background-color: #7C3302;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 5px;
    color: #E0DA4F;
    text-align: right;
    overflow: hidden;
    gap:20px
    
}

.dropdown p {
    width: 275px;;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    padding: 2%;
}

.nav-dropdown:hover .dropdown {
    display: block;
}

.menu_resp {
    display: none;
}

@media screen and (max-width: 1024px) {
    .Facclogo {
        height: 250px;
    }

    .menu p, .menu span {
        width: 130px;
    }
}

@media screen and (max-width: 768px) {
    .menu {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        position: relative;
    }

    .menu::before {
        top: 30%;
    }

    .Facclogo {
        height: 150px;
        margin-right: 10px;
    }

    .menu_resp {
        display: flex;
        font-size: 30px;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .menu-items {
        display: none;
        position: absolute;
        top: 40%;
        right: 10px;
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        text-align: center;
        background-color: #7C3302;
        color: #E0DA4F;
        padding: 20px 2%;
        border-radius: 15px;
        z-index: 10;
    }

    .menu-items {
        display: none;
    }
    
    .menu-items.open {
        display: flex; /* Assurez-vous que cela est correct */
    }

    .nav-dropdown {
        width: auto;
        text-align: center;
        margin: 10px 0;

    }

    .dropdown {
        position: static;
        display: none;
        background-color: transparent;
        box-shadow: none;
        padding: 0;
    }

    .nav-dropdown:hover .dropdown {
        display: flex;
        flex-direction: column;
    }

    .dropdown p {
        color: #E0DA4F;
        padding: 5px 0;
    }

   .nav-dropdown > span {
        width: 100%;
        text-align: center;
        padding: 10px 0;
    }
    .menu p{
        width: 100%;
        text-align: center;
        line-height: 2em;

    }
}

@media screen and (max-width: 480px) {
    .Facclogo {
        height: 120px;
    }

    .menu_resp {
        font-size: 25px;
        top: 15px;
        right: 15px;
    }

    .menu-items {
        width: 70%;
    }
}

@media screen and (max-width: 360px) {
    .menu {
        padding: 5px;
    }

    .Facclogo {
        height: 100px;
    }

    .menu_resp {
        font-size: 22px;
        top: 10px;
        right: 10px;
    }

    .menu-items {
        width: 70%;
    }

    .dropdown p {
        padding: 3px 0;
    }
}