/* ContactForm.css */

.bouton-e {
    background-color: #C4CEDE;
    color: #7C3302;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: auto;
    text-align: center;
    box-shadow: #7C3302;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    transition: 2s;
    
  }
  
  .bouton-e:hover {
    background-color: #BF8B63;
  }