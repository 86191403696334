.CarousselU {
  max-width: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16em; /* Changé de 250px à 15em */
  padding: 0 10%;
}

.images {
  display: flex;
  gap: 2%;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 100%;
  transition: transform 0.3s ease;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  width: 3em; /* Changé de 55px à 3em */
  height: 3em; /* Changé de 55px à 3em */
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow:first-of-type {
  left: 2%;
}

.arrow:last-of-type {
  right: 2%;
}

.pic {
  flex: 0 0 calc(33.33% - 1.33%);
  max-width: calc(33.33% - 1.33%);
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  overflow: hidden;
  padding-bottom: 0.5em; /* Changé de 10px à 0.5em */
}

.pic:hover {
  transform: scale(1.05);
}

.img {
  width: auto;
  height: auto;
  max-height: 11em; /* Changé de 180px à 11em */
  max-width: 100%;
  object-fit: contain;
  margin-bottom: 0.5em; /* Changé de 10px à 0.5em */
}

.image-name {
  text-align: center;
  font-size: 0.875em; /* Changé de 14px à 0.875em */
  margin: 0;
  padding: 0.3em 0; /* Changé de 5px à 0.3em */
  width: 100%;
  word-wrap: break-word;
}

/* Responsive Design */
@media (max-width: 75em) { /* 1200px */
  .CarousselU {
    height: auto;
    padding: 0 10%;
  }

  .images {
    width: 84%;
  }

  .pic {
    flex: 0 0 calc(50% - 1%);
    max-width: calc(50% - 1%);
    padding-bottom: 2em;
  }

  .arrow {
    width: 2.5em; /* Changé de 35px à 2.5em */
    height: 2.5em; /* Changé de 35px à 2.5em */
  }
}

@media (max-width: 48em) { /* 768px */
  .CarousselU {
    padding: 0 6%;
    height: 13em; /* Ajusté pour les écrans plus petits */
  }

  .images {
    width: 88%;
  }

  .pic {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .img {
    max-height: 13em; /* Changé de 220px à 13em */
  }

  .arrow {
    width: 2em; /* Changé de 30px à 2em */
    height: 2em; /* Changé de 30px à 2em */
  }
}

@media (max-width: 30em) { /* 480px */
  .CarousselU {
    height: 20%;
    padding-top: 3em; /* Ajusté pour les très petits écrans */
  }

  .pic {
    max-width: 80%;
  }

  .img {
    max-height: 11em; /* Changé de 180px à 11em */
  }

  .image-name {
    font-size: 0.75em; /* Changé de 12px à 0.75em */
  }

  .arrow {
    width: 1.75em; /* Changé de 28px à 1.75em */
    height: 1.75em; /* Changé de 28px à 1.75em */
  }
}

@media (max-width: 22.5em) { /* 360px */
  .CarousselU {
    height: 9em; /* Ajusté pour les écrans très étroits */
  }

  .img {
    max-height: 8em; /* Changé de 130px à 8em */
  }

  .image-name {
    font-size: 0.6875em; /* Changé de 11px à 0.6875em */
  }

  .arrow {
    width: 1.5em; /* Changé de 25px à 1.5em */
    height: 1.5em; /* Changé de 25px à 1.5em */
  }
}

/* Force mobile layout */
.CarousselU.mobile-always {
  padding: 0 6%;
}

.CarousselU.mobile-always .images {
  width: 88%;
}

.CarousselU.mobile-always .pic {
  flex: 0 0 100%;
  max-width: 100%;
}

.CarousselU.mobile-always .arrow {
  width: 1.75em; /* Changé de 28px à 1.75em */
  height: 1.75em; /* Changé de 28px à 1.75em */
}