.Edition2014 {
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.hero2014 {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}

.Sec {
    height: 300px;
    object-fit: cover;
    margin-right: 2%;
}

.M, .S{
    width: 100%;
    background-color: #283527;
    height: auto;
}

.Ma {
    color: beige;
}

.maraine {
    color: beige;
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 450px;
}

.A {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
}

.dimArt {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
}

.scol2024 {
    width: 100%;
    color: beige;
}

.sc {
    color: beige;
}

.Parteniaria {
    width: 100%;
}

/* Media Queries pour le responsive */

@media screen and (max-width: 1024px) {
    .hero2014 {
        flex-direction: column;
        gap: 20px;
    }

    .maraine {
        flex-direction: column;
        height: auto;
        padding: 20px 1%; /* Ajout de padding de 1% à gauche et à droite */
    }

    .dimArt {
        height: auto;
    }

    .Sec {
        height: auto;
        width: 100%;
        max-height: 400px;
    }
}

@media screen and (max-width: 768px) {
    .hero2014 img {
        width: 100%;
        height: auto;
        object-fit: cover;
        max-height: 350px;
    }

    .section {
        width: 96%;
        padding-left: 1%; /* Ajout de padding de 1% à gauche */
        padding-right: 1%; /* Ajout de padding de 1% à droite */
    }

    .dimArt {
        padding: 0 1%; /* Ajout de padding de 1% à gauche et à droite */
    }

    .maraine {
        gap: 15px;
    }
}

@media screen and (max-width: 480px) {
    .H2014, .M, .A, .S {
        font-size: 0.9em;
    }

    .hero2014, .maraine {
        gap: 15px;
    }

    .section {
        width: 94%;
        padding-left: 1%; /* Ajout de padding de 1% à gauche */
        padding-right: 1%; /* Ajout de padding de 1% à droite */
    }

    .Sec {
        max-height: 250px;
    }
}

@media screen and (max-width: 360px) {
    .H2014, .M, .A, .S {
        font-size: 0.8em;
    }

    .hero2014, .maraine {
        gap: 10px;
    }

    .section {
        width: 96%;
        padding-left: 1%; /* Ajout de padding de 1% à gauche */
        padding-right: 1%; /* Ajout de padding de 1% à droite */
    }

    .Sec {
        max-height: 200px;
    }
}